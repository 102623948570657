<template>
	<div v-show="!hideInput" class="form-control">
		<input type="file" hidden :id="name" v-bind="$attrs" ref="input" @change="onChange($event)" />
		<validation-message v-if="errors.length && showError" :messages="errors" />
	</div>
</template>

<script setup>
import ValidationMessage from '@/components/forms/ValidationMessage.vue'
import { useField } from 'vee-validate'
import { useTemplateRef } from 'vue'
import isHeic from '@/helpers/isHeic'
import heic2any from 'heic2any'

defineOptions({
	inheritAttrs: false
})

const props = defineProps({
	name: { type: String, required: true },
	showError: { type: Boolean, default: true },
	rules: {
		type: Object,
		default: () => null
	},
	hideInput: {
		type: Boolean,
		default: false
	}
})

const event = defineEmits(['change', 'error', 'update:modelValue', 'processing'])
const { errors, validate, setValue, setErrors } = useField(() => props.name, props.rules, {
	initialValue: null
})

const inputElement = useTemplateRef('input')

function normalizeFile(file) {
	return new Promise((resolve) => {
		if (isHeic(file)) {
			heic2any({
				blob: file,
				toType: 'image/jpeg'
			}).then((res) => {
				resolve(URL.createObjectURL(res))
			})
		}
	})
}

function getFileMeta(file) {
	return new Promise((resolve) => {
		const res = { width: null, height: null }
		if (file.type.split('/')[0] === 'image') {
			const image = document.createElement('img')
			image.onerror = () => resolve(res)
			image.onload = () => {
				res.width = image.width
				res.height = image.height
				resolve(res)
			}
			image.src = file.url
			return
		}
		if (file.type.split('/')[0] === 'video') {
			const video = document.createElement('video')
			video.autoplay = true
			video.muted = true
			video.onerror = () => resolve(res)
			video.onloadeddata = () => {
				res.duration = video.duration
				res.width = video.videoWidth
				res.height = video.videoHeight
				resolve(res)
			}
			video.src = file.url
			return
		}
		if (file.type.split('/')[0] === 'audio') {
			const audio = document.createElement('audio')
			audio.onerror = () => resolve(res)
			audio.onloadeddata = () => {
				res.duration = audio.duration
				resolve(res)
			}
			audio.src = file.url
			return
		}
		resolve(res)
	})
}

async function onChange(e) {
	event('processing', true)
	setErrors([])
	const file = e.target.files[0]
	file.url = isHeic(file) ? await normalizeFile(file) : URL.createObjectURL(file)

	const { width, height, duration } = await getFileMeta(file)
	file.width = width
	file.height = height
	file.duration = duration

	setValue(file)

	const { valid } = await validate()
	if (valid) {
		event('change', file)
		event('update:modelValue', file)
	} else {
		event('error', errors.value[0])
	}

	inputElement.value.value = null
	event('processing', false)
}
</script>
