<template>
	<transition-group name="fade">
		<component :is="currentPopup" @close="closePopup" :class="desktopClass" ref="dialogContainer" :style="pos" />
	</transition-group>
</template>

<script setup lang="ts">
import { ref, defineAsyncComponent, watch } from 'vue'
import { useRoute } from 'vue-router'
import { useCommonStore } from '@/stores/common'
import eventHelper from '@/helpers/event.helper.ts'

const commonStore = useCommonStore()
const route = useRoute()
const currentPopup = ref(null)
const desktopClass = ref(null)
const dialogContainer = ref(null)
const pos = ref(null)

const popupMap = new Map([['profileMenu', defineAsyncComponent(() => import('./ProfileMenu.vue'))]])

eventHelper.on('setPopupData', (data) => {
	const right = window.innerWidth - data.position.left - data.position.width
	const top = data.position.top + data.position.height + 12
	pos.value = {
		right: `${right}px`,
		top: `${top}px`
	}

	desktopClass.value = !commonStore.isMobileDevice && data.dropdownMode ? 'desktop-dropdown' : null
	currentPopup.value = popupMap.get(data.popup)
})
eventHelper.on('clearPopupData', () => {
	currentPopup.value = null
	desktopClass.value = null
})

const closePopup = () => {
	eventHelper.emit('clearPopupData')
}

watch(route, () => {
	closePopup()
})
</script>

<style style lang="scss">
.desktop-dropdown {
	position: fixed;

	.dialog-backdrop {
		background: transparent;
		position: static;
		border-radius: 16px 6px 16px 16px;

		&:before {
			content: '';
			position: fixed;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
		}
	}
	.dialog {
		position: relative;
		width: auto;
		border-radius: 16px 6px 16px 16px;
		background: #2c2d37;
		box-shadow:
			5px 4px 6.2px 0px rgba(0, 0, 0, 0.1),
			5px 20px 24.8px 11px rgba(0, 0, 0, 0.2);
	}
	.dialog-header,
	.dialog-close-btn {
		display: none;
	}

	.profile-menu {
		margin: -9px -10px;
	}

	.profile-menu-item {
		padding: 3px 10px;
		font-size: 14px;
		height: auto;
		transition:
			background-color 0.2s ease-in-out,
			color 0.2s ease-in-out;
		border-radius: 9px;
		min-height: 36px;
		display: flex;
		align-items: center;
		color: #bebec9;

		&:hover {
			color: #ffffff;
			background-color: #25272c;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}

	.profile-menu {
		margin-bottom: 20px;
	}
}
</style>
